import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import {
  POST_LIST_WIDGET_DEFAULTS,
  POST_LIST_SETTINGS_PARAMS,
} from '@wix/communities-blog-client-common/dist/src/constants/post-list-widget-constants';
import { getFeedPosts } from '../../../common/selectors/post-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getAppSettingsNumber, getAppSettingsValue } from '../../../common/selectors/app-settings-selectors';

class PostList extends Component {
  componentDidUpdate({ pageSize, featuredOnly }) {
    if (this.props.pageSize !== pageSize || this.props.featuredOnly !== featuredOnly) {
      this.props.fetchPostListPosts();
    }
  }

  render() {
    const { posts, isLoading, componentId } = this.props;
    const hasPosts = Boolean(posts.length);

    if (!isLoading && !hasPosts) {
      return <NoPostsFeed />;
    }

    return (
      <PostListProGallery
        layoutName={POST_LIST_WIDGET_DEFAULTS.layoutName}
        layoutType={POST_LIST_WIDGET_DEFAULTS.layoutType}
        entityCount={POST_LIST_WIDGET_DEFAULTS.entityCount}
        layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
        domId={componentId}
        allPosts={posts}
        currentPagePosts={posts}
        isLoading={isLoading}
        showCreatePostAction={false}
        section={SECTION_POST_LIST}
        hideThreeDots={true}
        hideAuthorBadge={true}
      />
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  return {
    posts: getFeedPosts(state),
    isLoading: getIsEntityLoading(state, 'postListPosts'),
    componentId: host.id,
    pageSize: getAppSettingsNumber(
      state,
      POST_LIST_SETTINGS_PARAMS.entityCount.wixParam,
      POST_LIST_SETTINGS_PARAMS.entityCount.default,
    ),
    featuredOnly: getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath }),
    fetchPostListPosts: actions.fetchPostListPostsPromisified,
  };
};

export default connect(mapRuntimeToProps)(PostList);
